import React, { useState } from 'react';
import { Card, Row, Col, Button, Modal, Form, Input, Select, message } from 'antd';
import BusinessService from 'src/services/BusinessService';

const { Option } = Select;

const CommercialInfo = ({ businessId, fetchBusinessData, originalData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalForm] = Form.useForm();

  // Función para guardar la información comercial
  const handleSaveCommercialInfo = async () => {
    try {
      const values = await modalForm.validateFields(); // Validar campos
      const dataToSend = {
        name: values.name,
        type: values.type || null,
        phone: values.phone || null,
        email: values.email || null,
        website: values.website || null,
      };

      const response = await BusinessService.setComercialInformation(businessId, dataToSend);

      if (response?.status === 'OK') {
        message.success('Información comercial actualizada correctamente');
        setIsModalVisible(false); // Cerrar el modal
        fetchBusinessData(businessId); // Refrescar datos
      } else {
        message.warning(
          response?.message || 'Hubo un problema al actualizar la información comercial'
        );
      }
    } catch (error) {
      console.error('Error al guardar la información comercial:', error);
      message.error('Ocurrió un error al intentar actualizar la información.');
    }
  };

  return (
    <Card className="bg-white rounded shadow-none mt-4">
      <Row>
        <Col xs={12}>
          <h3 className="font-semibold">Información comercial</h3>
          <p style={{ color: '#6c757d' }}>
            La información de su empresa se muestra en sus páginas en línea y mensajes a clientes.
          </p>
        </Col>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <p>
                <strong>Nombre del negocio:</strong>
              </p>
              <p>
                <strong>Tipo de negocio:</strong>
              </p>
              <p>
                <strong>Teléfono de negocios:</strong>
              </p>
              <p>
                <strong>Email de negocios:</strong>
              </p>
              <p>
                <strong>Sitio web de negocios:</strong>
              </p>
            </Col>
            <Col xs={12}>
              <p>{originalData?.name || '-'}</p>
              <p>{originalData?.type || '-'}</p>
              <p>{originalData?.phone || '-'}</p>
              <p>{originalData?.email || '-'}</p>
              <p>{originalData?.website || '-'}</p>
            </Col>
          </Row>
          {/* Contenedor del botón */}
<div
  style={{
    display: 'flex',
    justifyContent: 'flex-end', // Alinea el botón a la derecha
    marginTop: '16px', // Espaciado superior
  }}
>
  <Button
    type="primary"
    style={{
      fontWeight: 400, // Grosor de texto más ligero
      fontSize: '15px', // Tamaño de fuente
    }}
    onClick={() => setIsModalVisible(true)} // Abre el modal
  >
    Editar información comercial
  </Button>
</div>

        </Col>
      </Row>

      {/* Modal para editar información comercial */}
      <Modal
        title="Editar información comercial"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)} // Cerrar modal
        footer={[
          <Button key="cancel" onClick={() => setIsModalVisible(false)}>
            Cancelar
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveCommercialInfo}>
            Guardar
          </Button>,
        ]}
      >
        <Form form={modalForm} layout="vertical" initialValues={originalData}>
          <Form.Item
            label="Nombre del negocio"
            name="name"
            rules={[{ required: true, message: 'El nombre es obligatorio' }]}
          >
            <Input placeholder="Nombre del negocio" />
          </Form.Item>
          <Form.Item label="Tipo de negocio" name="type">
            <Select placeholder="Selecciona el tipo de negocio">
              <Option value="bar">Bar</Option>
              <Option value="restaurant">Restaurante</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Teléfono" name="phone">
            <Input placeholder="Teléfono de contacto" />
          </Form.Item>
          <Form.Item label="Correo electrónico" name="email">
            <Input type="email" placeholder="Correo electrónico" />
          </Form.Item>
          <Form.Item label="Sitio web" name="website">
            <Input placeholder="Sitio web" />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default CommercialInfo;
