import React, { useMemo } from 'react'
import { AsideMenuList } from './AsideMenuList'
import { useHtmlClassService } from '../../../_core/MetronicLayout'
import { TopbarUser } from '../../header/TopbarUser'

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      // asideMenuAttr: uiService.getAttributes("aside_menu"),
      // ulClasses: uiService.getClasses("aside_menu_nav", true),
      // asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    }
  }, [uiService])

  const topbarUserStyle = {
    //position: "fixed", // Fija el componente para que esté visible en toda la pantalla
    top: '10px', // Ajusta la posición superior
    right: '10px', // Ajusta la posición derecha
    zIndex: 1050, // Asegura que esté por encima de otros elementos
    backgroundColor: '#fff', // Fondo blanco para visibilidad
    padding: '10px', // Espaciado interno
    borderRadius: '8px', // Opcional: diseño más amigable
    //boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Sombra para visibilidad
    //width: "auto", // Se adapta al contenido
  }

  const mobileStyle = `
  @media (max-width: 768px) {
    #kt_aside_menu {
      width: 100% !important; /* Ocupa todo el ancho en dispositivos móviles */
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important; /* Sombra más pronunciada y difusa */
      border-radius: 0px; /* Sin bordes redondeados */
    }

    .topbar-user {
      display: none !important; /* Oculta el TopbarUser en móviles */
    }
  }
`

  return (
    <>
      {/* Añadimos estilos para móviles directamente */}
      <style>{mobileStyle}</style>

      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="2"
        className={`aside-menu my-7 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
        style={{
          overflow: 'visible',
          width: '70px', // Versión Desktop
          //boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra para Desktop
        }}
      >
        {/* <div className="topbar-user" style={topbarUserStyle}>
          <TopbarUser />
        </div> */}

        <AsideMenuList layoutProps={layoutProps} />
      </div>
      {/* end::Menu Container */}
    </>
  )
}
