import React, { useState, useEffect } from 'react'
import { Row, Col, Grid } from 'antd'
import SubMenu from './SubMenu'
import GeneralSettings from './components/GeneralSettings'
import Schedules from './components/Schedules'
import CommercialInfo from './components/CommercialInfo'
import BusinessAddress from './components/BusinessAddress'
import listMenu from './constants/menuConfig'

import BusinessService from 'src/services/BusinessService'

export default function SettingsGeneral({ settingFormInfo }) {
  const [businessId, setBusinessId] = useState(null)
  const [schedules, setSchedules] = useState([])
  const [originalData, setOriginalData] = useState(null)
  const { useBreakpoint } = Grid // Para manejar puntos de quiebre
  const screens = useBreakpoint()

  // Función para obtener datos del negocio
  const fetchBusinessData = async (id) => {
    try {
      const response = await BusinessService.getBusinessById(id)
      if (response && response.data) {
        console.log('Datos del negocio obtenidos:', response.data)
        setSchedules(response.data.schedules || [])
        setOriginalData({
          name: response.data.name || '',
          type: response.data.type || '',
          phone: response.data.phone || '',
          email: response.data.email || '',
          website: response.data.website || '',
          address: response.data.address || '',
          colony: response.data.colony || '',
          city: response.data.city || '',
          postal_code: response.data.postal_code || '',
          country_id: response.data.country_id || '',
          state_province: response.data.state_province || '',
        })
        return response.data
      } else {
        console.warn('No se pudieron obtener los datos del negocio')
      }
    } catch (error) {
      console.error('Error al obtener los datos del negocio:', error)
    }
  }

  // Obtener el businessId desde localStorage cuando se monte el componente
  useEffect(() => {
    const storedBusinessId = localStorage.getItem('provider_tappit_user_business_id')
    if (storedBusinessId) {
      const parsedBusinessId = JSON.parse(storedBusinessId)
      setBusinessId(parsedBusinessId)
    } else {
      console.error('No se encontró el businessId en localStorage')
    }
  }, [])

  return (
    <Row gutter={[16, 16]}>
      {/* Menú lateral */}
      <Col
        xs={24}
        sm={6}
        md={5}
        lg={5}
        style={{
          marginBottom: screens.xs ? '16px' : 0, // Espaciado inferior en móviles
        }}
      >
        <SubMenu settingsList={listMenu} />
      </Col>

      {/* Contenido principal */}
      <Col
        xs={24}
        sm={18}
        md={19}
        lg={19}
        style={{
          paddingLeft: screens.xs ? 0 : '16px', // Espaciado lateral dinámico
        }}
      >
        {/* Componente Configuración General (GeneralSettings) */}
        <GeneralSettings businessId={businessId} fetchBusinessData={fetchBusinessData} />

        {/* Componente Horario (Schedules) */}
        <Schedules
          businessId={businessId}
          schedules={schedules}
          setSchedules={setSchedules}
          fetchBusinessData={fetchBusinessData}
        />

        {/* Componente Información Comercial (CommercialInfo) */}
        <CommercialInfo
          businessId={businessId}
          fetchBusinessData={fetchBusinessData}
          originalData={originalData}
        />

        {/* Componente: Dirección de Negocios (BusinessAddress) */}
        <BusinessAddress
          businessId={businessId}
          fetchBusinessData={fetchBusinessData}
          originalData={originalData}
        />
      </Col>
    </Row>
  )
}
