import React, { useMemo, useState, useEffect } from 'react'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import { AnimateLoading } from '../../../_partials/controls'
import { Brand } from '../brand/Brand'
import { QuickSearch } from '../extras/offcanvas/QuickSearch'
import SessionService from 'src/services/SessionService'
import { useHistory } from 'react-router-dom'
import { Select, notification } from 'antd'
import { TopbarUser } from '../header/TopbarUser' // Importar TopbarUser
import firebaseHelper from 'src/utils/firebaseHelper' // Importamos el helper de Firebase
import useBookingAlerts from 'src/utils/firebaseAlert' // Ruta correcta

export function Header() {
  const uiService = useHtmlClassService()
  const history = useHistory()
  const [businesses, setBusinesses] = useState([])
  const [selectedBusiness, setSelectedBusiness] = useState(null)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768) // Responsividad inicial

  useBookingAlerts()

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(uiService.config, 'header.menu.self.display'),
    }
  }, [uiService])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await SessionService.getSession()
        const userBusinesses = response.data.business
        setBusinesses(userBusinesses)

        const savedBusinessId = localStorage.getItem('provider_tappit_user_business_id')
        setSelectedBusiness(savedBusinessId || userBusinesses[0]?.business_id)
      } catch (error) {
        console.error('Error fetching session data:', error)
        notification.error({
          message: 'Error',
          description: 'No se pudieron obtener los datos de sesión.',
        })
      }
    }

    fetchSessionData()
  }, [])

  const handleBusinessChange = (value) => {
    setSelectedBusiness(value)

    const currentPath = window.location.pathname
    const updatedPath = currentPath.replace(/\/business\/[^/]+/, `/business/${value}`)

    console.log(`Cambiando a business ID: ${value} en la URL: ${updatedPath}`)

    try {
      localStorage.setItem('provider_tappit_user_business_id', value)
      window.location.href = updatedPath
    } catch (error) {
      console.error('Error al cargar los datos del negocio:', error)
    }
  }

  return (
    <>
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
        style={{
          display: 'flex',
          flexWrap: isMobile ? 'wrap' : 'nowrap', // Apilar en móvil
          flexDirection: 'row', // Mantener fila en todas las vistas
          justifyContent: 'space-between', // Separar elementos
          alignItems: 'center', // Centrar verticalmente
          padding: '2px 10px',
          borderBottom: '1px solid #ddd',
          backgroundColor: '#fff',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1100,
        }}
      >
        {/* Logo */}
        <div
          style={{
            flex: '0 0 auto',
            display: 'flex',
            alignItems: 'center',
            marginLeft: isMobile ? '45px' : '70px', // Aumenta el margen izquierdo para mover el logo más a la derecha
            marginBottom: isMobile ? '4px' : '0',
          }}
        >
          <a href="/visitas">
            <img
              src="/media/logos/logo-panel.png"
              alt="Logo"
              style={{
                maxWidth: isMobile ? '80px' : '150px', // Aumenta el tamaño del logo en ambas vistas
                height: 'auto',
                cursor: 'pointer',
              }}
            />
          </a>
        </div>

        {/* Contenedor del select, barra de búsqueda y TopbarUser */}
        <div
          style={{
            flex: '1 1 auto', // El contenedor ocupa el espacio disponible
            display: 'flex',
            flexDirection: 'row', // Mantener los elementos en una fila
            justifyContent: isMobile ? 'space-between' : 'flex-end', // Distribuir los elementos en móvil
            alignItems: 'center', // Alinear verticalmente
            gap: isMobile ? '0' : '20px', // Espaciado entre elementos
          }}
        >
          {/* Select */}
          <Select
            style={{
              fontSize: '1rem',
              width: isMobile ? '70%' : '220px', // Aumenta el ancho en móvil y desktop
              backgroundColor: 'transparent',
              border: '1px solid #d9d9d9',
              marginLeft: isMobile ? '20px' : '20px', // Más margen a la derecha para ambas vistas
            }}
            value={selectedBusiness}
            onChange={handleBusinessChange}
            bordered={false}
            dropdownStyle={{
              backgroundColor: 'white',
              border: '1px solid #d9d9d9',
              zIndex: 1500,
            }}
            placeholder="Seleccionar negocio"
          >
            {businesses.map((business) => (
              <Select.Option key={business.business_id} value={business.business_id}>
                {business.name}
              </Select.Option>
            ))}
          </Select>

          {/* Barra de búsqueda - Solo en desktop */}
          {/* {!isMobile && (
            <div
              style={{
                flex: '0 0 auto', // Evita que crezca más de lo necesario
                display: 'flex',
                justifyContent: 'center', // Centrar la barra de búsqueda
                width: '200px', // Ancho fijo para desktop
                marginLeft: '20px', // Separar la barra de búsqueda del select
              }}
            >
              <QuickSearch />
            </div>
          )} */}

          {/* TopbarUser */}
          <div
            style={{
              flex: '0 0 auto', // Evita que crezca más de lo necesario
              display: 'flex',
              justifyContent: 'flex-end', // Alinear a la derecha
              alignItems: 'center',
              width: isMobile ? 'auto' : '60px', // Asegura un ancho específico en desktop
              marginLeft: isMobile ? 'auto' : '20px', // Ajustar márgenes según el tamaño de pantalla
              position: isMobile ? 'relative' : 'static', // Evita interferencias en móvil
              zIndex: 1, // Asegura que no se superponga
            }}
          >
            <TopbarUser />
          </div>
        </div>
      </div>
    </>
  )
}
