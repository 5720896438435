import React, { useState, useEffect } from 'react'
import { Card, Form, Input, Button, Upload, Image, message, Select } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import BusinessService from 'src/services/BusinessService'
import UploadService from 'src/services/UploadService'
import { BASE_URL } from 'src/services/UploadService'

const GeneralSettings = ({ businessId, fetchBusinessData }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [selectedIconImage, setSelectedIconImage] = useState(null)

  // Cargar datos al montar el componente
  useEffect(() => {
    if (businessId) {
      loadBusinessData()
    }
  }, [businessId])

  // Función para cargar datos del negocio
  const loadBusinessData = async () => {
    try {
      const data = await fetchBusinessData(businessId)
      if (data) {
        initializeForm(data)
      }
    } catch (error) {
      console.error('Error al cargar los datos del negocio:', error)
    }
  }

  // Inicializa el formulario con los datos del negocio
  const initializeForm = (data) => {
    form.setFieldsValue({
      name: data.name || '',
      location_url: `${process.env.REACT_APP_WEB_URL}/negocio/${data.slug || ''}`,
      use_booking: data.use_booking,
    })

    if (data.image) {
      setCurrentImage(`${BASE_URL}${data.image.replace(/\\/g, '/')}`)
    } else {
      setCurrentImage(null)
    }

    if (data.icon_company) {
      setSelectedIconImage(`${BASE_URL}${data.icon_company.replace(/\\/g, '/')}`)
    } else {
      setSelectedIconImage(null)
    }

    // Manejar image_address (opcional, pero útil para consistencia)
    if (data.image_address) {
      setCurrentImage(`${BASE_URL}${data.image_address.replace(/\\/g, '/')}`)
    }
  }

  // Manejo de la actualización de datos
  const handleUpdateData = async () => {
    try {
      setLoading(true)

      const values = await form.validateFields()

      let imageUrl = currentImage ? currentImage.replace(BASE_URL, '').replace(/\\/g, '/') : ''
      let imageAddressUrl = currentImage
        ? currentImage.replace(BASE_URL, '').replace(/\\/g, '/')
        : ''
      let iconUrl = selectedIconImage
        ? selectedIconImage.replace(BASE_URL, '').replace(/\\/g, '/')
        : ''

      // Subir el logo de la compañía si es necesario
      if (values.file_image && values.file_image.length > 0) {
        try {
          const logoResponse = await UploadService.uploadImage(
            values.file_image[0].originFileObj,
            'business',
          )
          if (logoResponse.success) {
            imageUrl = logoResponse.filename.replace(/\\/g, '/')
            imageAddressUrl = logoResponse.filename.replace(/\\/g, '/') // Asignar también a `image_address`
          } else {
            throw new Error(logoResponse.message || 'Error al subir el logo')
          }
        } catch (error) {
          console.error('Error subiendo el logo:', error)
          message.error('Error al subir el logo. Inténtalo nuevamente.')
          setLoading(false)
          return
        }
      }

      // Subir el icono de ubicación si es necesario
      if (values.location_icon && values.location_icon.length > 0) {
        try {
          const iconResponse = await UploadService.uploadImage(
            values.location_icon[0].originFileObj,
            'business',
          )
          if (iconResponse.success) {
            iconUrl = iconResponse.filename.replace(/\\/g, '/')
          } else {
            throw new Error(iconResponse.message || 'Error al subir el ícono')
          }
        } catch (error) {
          console.error('Error subiendo el ícono:', error)
          message.error('Error al subir el ícono. Inténtalo nuevamente.')
          setLoading(false)
          return
        }
      }

      // Construir los datos para enviar
      const dataToSend = {
        name: values.name,
        location_url: form.getFieldValue('location_url'),
        use_booking: values.use_booking, // typo de reserva
        icon_company: iconUrl || null,
        image: imageUrl || null,
        image_address: imageAddressUrl || null, // Nuevo campo agregado
      }

      console.log('Datos a enviar:', dataToSend)
      const response = await BusinessService.setGeneralData(businessId, dataToSend)

      if (response?.status === 'OK') {
        message.success('Datos actualizados correctamente')
        loadBusinessData() // Recargar los datos actualizados
      } else {
        throw new Error(response?.message || 'Error desconocido al actualizar los datos')
      }
    } catch (error) {
      console.error('Error al actualizar los datos:', error)
      message.error(`Ha ocurrido un error: ${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card className="bg-white rounded shadow-none mb-4">
      <h2 className="fw-bold mb-4">Configuración General</h2>

      <Form form={form} layout="vertical">
        {/* Nombre del negocio */}
        <Form.Item
          label="Nombre del lugar"
          name="name"
          rules={[{ required: true, message: 'El nombre es obligatorio' }]}
        >
          <Input placeholder="Nombre del lugar" />
        </Form.Item>

        {/* URL de ubicación */}
        <Form.Item label="ID y URL de la ubicación" name="location_url">
          <Input.Group compact>
            <Form.Item name="location_url" noStyle>
              <Input
                readOnly
                style={{
                  width: 'calc(105% - 110px)', // Ancho dinámico
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px 0 0 4px', // Esquinas redondeadas para el input
                  height: '38px',
                }}
              />
            </Form.Item>
            <Button
              type="primary"
              style={{
                borderRadius: '0 4px 4px 0', // Esquinas redondeadas para el botón
                height: '38px', // Alineado con el input
                fontSize: '16', // Tamaño de fuente
              }}
              onClick={() => {
                const url = form.getFieldValue('location_url')
                if (url) {
                  navigator.clipboard.writeText(url)
                  message.success('URL copiada al portapapeles')
                } else {
                  message.warning('El campo está vacío. No hay nada que copiar.')
                }
              }}
            >
              Copiar
            </Button>
          </Input.Group>
        </Form.Item>

        {/* Modo de reseva */}
        <Form.Item
          label="Tipo de reserva"
          name="use_booking" // tipo de reserva
          rules={[{ required: true, message: 'Por favor selecciona un tipo de reserva' }]}
        >
          <Select placeholder="Selecciona una opción">
            <Select.Option value="waitlist">Lista de espera</Select.Option>
            <Select.Option value="booking">Reservación</Select.Option>
            <Select.Option value="both">Ambas</Select.Option>
          </Select>
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '16px',
            alignItems: 'flex-start',
          }}
        >
          {/* Sección Logo de la compañía */}
          <div style={{ flex: 1 }}>
            <h3>Logo de la compañía</h3>
            <Form.Item
              label="Logo de la compañía"
              name="file_image"
              valuePropName="fileList"
              getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
            >
              <Upload
                name="file"
                listType="picture"
                maxCount={1}
                beforeUpload={(file) => {
                  setCurrentImage(URL.createObjectURL(file))
                  return false
                }}
                onRemove={() => setCurrentImage(null)}
              >
                <Button icon={<UploadOutlined />}>Subir Imagen</Button>
              </Upload>
            </Form.Item>

            {currentImage && (
              <Image
                src={currentImage}
                alt="Vista previa del logo"
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'contain',
                  marginBottom: '16px',
                  border: '1px solid #d9d9d9',
                  borderRadius: '8px',
                }}
              />
            )}
          </div>

          {/* Sección Icono de ubicación */}
          <div style={{ flex: 1 }}>
            <h3>Icono de ubicación</h3>
            <Form.Item
              label="Icono de ubicación"
              name="location_icon"
              valuePropName="fileList"
              getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
            >
              <Upload
                name="file_icon"
                listType="picture"
                maxCount={1}
                beforeUpload={(file) => {
                  setSelectedIconImage(URL.createObjectURL(file))
                  return false
                }}
                onRemove={() => setSelectedIconImage(null)}
              >
                <Button icon={<UploadOutlined />}>Subir Imagen</Button>
              </Upload>
            </Form.Item>

            {selectedIconImage && (
              <Image
                src={selectedIconImage}
                alt="Icono de ubicación"
                style={{
                  width: '150px',
                  height: '150px',
                  objectFit: 'contain',
                  marginBottom: '16px',
                  border: '1px solid #d9d9d9',
                  borderRadius: '8px',
                }}
              />
            )}
          </div>
        </div>

        {/* Botón Actualizar */}
        {/* Contenedor del botón */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end', // Alinea el botón a la derecha
            marginTop: '16px',
          }}
        >
          {/* Botón Actualizar */}
          <Button
            type="primary"
            onClick={handleUpdateData}
            loading={loading}
            style={{
              fontWeight: 400, // Grosor de la fuente más ligero
              fontSize: '16px', // Tamaño de fuente
            }}
          >
            {loading ? 'Actualizando...' : 'Actualizar'}
          </Button>
        </div>
      </Form>
    </Card>
  )
}

export default GeneralSettings
