import React, { useMemo, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import objectPath from 'object-path'
import { AsideMenu } from './aside-menu/AsideMenu'
import { useHtmlClassService } from '../../_core/MetronicLayout'

export function Aside() {
  const uiService = useHtmlClassService()
  const location = useLocation() // Para detectar cambios de ruta
  const [isMenuOpen, setIsMenuOpen] = useState(
    localStorage.getItem('menuOpen') === 'true', // Recupera estado del menú
  )

  useEffect(() => {
    // Cerrar el menú cuando cambia de ruta en pantallas pequeñas
    if (window.innerWidth < 992) {
      setIsMenuOpen(false)
    }
  }, [location.pathname])

  useEffect(() => {
    // Guardar el estado del menú en localStorage
    localStorage.setItem('menuOpen', isMenuOpen)
  }, [isMenuOpen])

  const layoutProps = useMemo(() => {
    return {
      disableScroll: objectPath.get(uiService.config, 'aside.menu.dropdown') === 'true' || false,
      asideClassesFromConfig: uiService.getClasses('aside', true),
      disableAsideSelfDisplay: objectPath.get(uiService.config, 'aside.self.display') === false,
      headerLogo: uiService.getLogo(),
    }
  }, [uiService])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      {/* Botón de hamburguesa */}
      <div
        style={{
          position: 'fixed',
          top: '4px',
          left: '10px',
          zIndex: 1100,
        }}
        className="d-lg-none"
      >
        <button
          onClick={toggleMenu}
          style={{
            background: 'none',
            border: 'none',
            fontSize: '27px',
            cursor: 'pointer',
          }}
        >
          ☰
        </button>
      </div>

      {/* Menú lateral */}
      <div
        id="kt_aside"
        className={`aside aside-left ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}
        style={{
          position: 'fixed',
          top: '0',
          left: isMenuOpen || window.innerWidth >= 992 ? '0' : '-350px', // Ocultar completamente en móvil cuando está cerrado
          height: '100%',
          zIndex: 1050,
          backgroundColor: '#fff',
          transition: 'left 0.3s ease-in-out', // Animación suave para abrir/cerrar
        }}
      >
        {/* Logo */}
        {layoutProps.disableAsideSelfDisplay && (
          <div
            className="header-logo"
            style={{
              padding: '20px',
              textAlign: 'center',
            }}
          >
            <Link to="/">
              <img alt="logo" src={layoutProps.headerLogo} style={{ maxWidth: '250px' }} />
            </Link>
          </div>
        )}

        {/* Menú adaptado */}
        <AsideMenu
          disableScroll={layoutProps.disableScroll}
          isMobile={window.innerWidth < 992} // Pasamos si está en móvil
        />
      </div>
    </>
  )
}
